import React, { Component } from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { loginUser } from '../../Redux/Actions/Login';

// Containers
const Page = React.lazy(() => import('../../Containers/Layout/Page/'));

export default function ProtectedRoute(props){

   const logged_in = useSelector(state => state.user.logged_in);

   //pokud jsme prihlaseni, tak vratime DefaultLayout, jinak redirectujeme na login page
   return (
      logged_in ? (
         <Route path={props.path} name={props.name} render={props => <Page {...props}/>} />
      ) : (
         <Route render={() => <Redirect to="/login" /> }/>
      )
   );
 
}