import { TOAST_NOTIFY, TOAST_DELETE } from '../Types';

export const toastNotify = (color, header, body) => {
   return (dispatch) => {
      const toast = {
         color: color,
         header: header,
         body: body
      };
      dispatch(pushToastNotify(toast));
   }
}

const pushToastNotify = toast => (
   {
      type: TOAST_NOTIFY,
      payload: toast
   }
);

export const toastRemove = idx => (
   {
      type: TOAST_DELETE,
      payload: idx
   }
);