import {  createStore, applyMiddleware, compose } from 'redux';
import ReduxThunk from 'redux-thunk';

import rootReducer from './Redux/Reducers';
import logger from 'redux-logger';

import { persistReducer } from 'redux-persist';

import storage from 'redux-persist/lib/storage';

import createEncryptor from 'redux-persist-transform-encrypt';

const encryptor = createEncryptor({
    secretKey: 'sd54Gbřžd&cerfWewrě@qaf877g/g,ksQq',
    onError: error => {
        console.error("Persist storage encryptor failed!");
    }
});

const persistConfig = {
    key: 'main',
    storage,
    transform: [encryptor],
    whitelist: [
        'user',
    ]
};

//nastavime persistentni overflow nad reducerem
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, {}, compose(applyMiddleware(ReduxThunk, logger)));

export default store;