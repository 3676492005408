import React from 'react';
import { BrowserRouter, Route, Switch} from 'react-router-dom';

import ProtectedRoute from './Component/ProtectedRoute/';

import './App.css';
import 'startbootstrap-sb-admin-2/css/sb-admin-2.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import store from './store';

const Loading = () => <div>Načítám...</div>;

const DefaultLayout = React.lazy(() => import('./Containers/Layout/Page/'));
const Login = React.lazy(() => import('./Containers/Login'));
const Page404 = React.lazy(() => import('./Containers/Page404'));

function App() {

  const persistor = persistStore(store);

  return (
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={<Loading />} persistor={persistor}>
          <React.Suspense fallback={<Loading />}>
            <Switch>
              <Route exact path="/login" name="Login" render={props => <Login {...props} />} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
              <ProtectedRoute path="/" name="Home" />
            </Switch>
          </React.Suspense>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
