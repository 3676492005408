import { FETCH_USER } from '../Types';
import update from 'immutability-helper';

const def_state = {
   api_token: null,
   user: null,
   logged_in: false,
};

export default function user(state = def_state, action){
   switch(action.type){
      case FETCH_USER:
         return update(state, {api_token: {$set: action.payload.api_token}, user: {$set: action.payload.user}, logged_in: {$set: true}});
      default:
         return state;
   }
};