import store from '../store';
import { toastNotify } from './Actions/Toast';

export default class Logger{
   static error(message){
      const dispatch = store.dispatch;
      dispatch(toastNotify("danger", "Ups, něco se pokazilo :(", message));
   }

   static success(message){
      const dispatch = store.dispatch;
      dispatch(toastNotify("success", "Výborně!", message));
   }

   static log(message){
      const dispatch = store.dispatch;
      dispatch(toastNotify("primary", "Informace", message));
   }

   static warn(message){
      const dispatch = store.dispatch;
      dispatch(toastNotify("warning", "Pozor", message));
   }
}