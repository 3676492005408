import { TOAST_NOTIFY, TOAST_DELETE } from '../Types';
import update from 'immutability-helper';

const def_state = {
   toasts: [],
   last_index: 0,
};

export default function toast(state = def_state, action){
   switch(action.type){
      case TOAST_NOTIFY:
         return update(state, {toasts: {$push: [{...action.payload, index: state.last_index}]}, last_index: {$set: state.last_index+1}});
      case TOAST_DELETE:
         return update(state, {toasts: {$unset: [action.payload]}});
      default:
         return state;
   }
};