export const FETCH_USER = 'FETCH_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGIN_STATUS = 'LOGIN_STATUS';

/** SETTINGS **/
/*objednavky*/
export const ORDERS_TIMES = "ORDERS_TIMES";
export const DURATIONS_TIMES = "DURATIONS_TIMES";
export const ORDERS_DATES_SELECTED_ADD = "ORDERS_DATES_SELECTED";
export const ORDERS_DATES_SELECTED_DELETE = "ORDERS_DATES_SELECTED_DELETE";
export const ORDERS_DATES_SELECTED_FLUSH = "ORDERS_DATES_SELECTED_FLUSH";
export const ORDERS_TIMES_SELECTED = "ORDERS_TIMES_SELECTED";
export const SETTINGS_CALENDAR_FLUSH = "SETTINGS_CALENDAR_FLUSH";
export const ORDERS_DURATION_TIME = "ORDERS_DURATION_TIME";
export const CALENDAR_DISALLOW = "CALENDAR_DISALLOW";
export const CALENDAR_ORDER_FROM = "CALENDAR_ORDER_FROM";
export const CALENDAR_ORDER_TO = "CALENDAR_ORDER_TO";

/** DASHBOARD **/
//vraci pocet různých věcí (objednávek, zákazníků...) do kartiček
export const DASHBOARD_COUNTS = "DASHBOARD_COUNTS";

/** TOAST **/
export const TOAST_NOTIFY = "TOAST_NOTIFY";
export const TOAST_DELETE = "TOAST_DELETE";

/** ZAKAZNICI **/
export const ORDERS_DAY = "ORDERS_DAY";
export const ORDERS_SUMMARY = "ORDERS_SUMMARY";
export const ORDERS_ZAKAZNIK = "ORDERS_ZAKAZNIK";
export const ORDERS_MONTH = "ORDERS_MONTH";
export const ORDERS_USER_FORM = "ORDERS_USER_FORM";