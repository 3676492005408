import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';

import login from './Login';
import user from './User';
import settings from './Settings';
import dashboard from './Dashboard';
import toast from './Toast';
import zakaznici from './Zakaznici';

//constanta
import { LOGOUT_USER } from '../Types';

const reducer = combineReducers({
   login: login,
   user: user,
   settings: settings,
   dashboard: dashboard,
   toast: toast,
   zakaznici: zakaznici,
 });
 
 const rootReducer = (state, action) => {
    if(action.type === LOGOUT_USER){
       //smazeme persistentni uloziste
       storage.removeItem('persist:main');
       //vymazeme redux
       state = undefined;
    }
    return reducer(state, action);
 };
 
 export default rootReducer;
 