import { LOGIN_STATUS, LOGOUT_USER } from '../Types';
import { fetchUser } from './User';
import Axios_noheaders from '../../_axios_noheaders';

/* asynchronni fce, ktere obstaravaji dotazy do api a maji minimalni logiku */

export const loginUser = (user_login) => {
   return(dispatch) => {
      return Axios_noheaders.get("/login", {params: {name: user_login.name, password: user_login.password}}).then(response => {
         dispatch(fetchUser(response.data));
         dispatch(setLoginSuccess());
      }).catch(error => {
         console.log(error);
         dispatch(setLoginError(true));
      });
   }
};


/* synchronni fce, ktere se volaji na zaklade logiky vyse */

export const setLoginPending = () => {
   return {
      type: LOGIN_STATUS,
      payload: {
         loginPending: true,
         loginError: false,
         loginSuccess: false
      }
   }
};

export const setLoginError = error => {
   return {
      type: LOGIN_STATUS,
      payload: {
         loginPending: false,
         loginError: error,
         loginSuccess: false
      }
   }
};

export const setLoginSuccess = () => {
   return {
      type: LOGIN_STATUS,
      payload: {
         loginPending: false,
         loginError: false,
         loginSuccess: true
      }
   }
};

export const logoutUser = () => {
   return{
      type: LOGOUT_USER,
      payload: {}
   }
}