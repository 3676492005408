import { ORDERS_DAY, ORDERS_SUMMARY, ORDERS_ZAKAZNIK, ORDERS_MONTH, ORDERS_USER_FORM } from '../Types';
import { ZAKAZNIK_ORDER_FORM } from '../Types/Zakaznici';
import { processApiRoutine } from 'redux-thunk-routine-helpers/dist/reducers';
import { default_api } from 'redux-thunk-routine-helpers/dist/defaults';
import update from 'immutability-helper';

const def_state = {
   novy_zakaznik: {},
   orders: {
      order_day: [],
      summary: [],
      zakaznik: {},
      //kompletni seznam objednavek pro dany mesic
      orders_month: [],
      //formular pro objednani zakaznika
      form: null,
   },
   zakaznik: {},
   ZAKAZNIK_ORDER_FORM: default_api,
};

export default function user(state = def_state, action){
   const api_routine = processApiRoutine(state, action);
   if(api_routine !== false) return api_routine;


   switch(action.type){
      case ORDERS_DAY:
         return update(state, {orders: {order_day: {$set: action.payload}}});
      case ORDERS_SUMMARY:
         return update(state, {orders: {summary: {$set: action.payload}}});
      case ORDERS_ZAKAZNIK:
         return update(state, {orders: {zakaznik: {$set: action.payload}}});
      case ORDERS_MONTH:
         return update(state, {orders: {orders_month: {$set: action.payload}}});
      case ORDERS_USER_FORM:
         return update(state, {orders: {form: {$set: action.payload}}});
      default:
         return state;
   }
};