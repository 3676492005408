import axios from 'axios';

const timeout = 10000;
const api_url = "https://api.pneuservis-hranice.cz";
//const api_url = "http://192.168.80.14:8000";
//const api_url = "http://109.164.78.56:8014";

const Axios = axios.create({
    baseURL: api_url,
    timeout: timeout
});

export default Axios
