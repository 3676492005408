import { FETCH_USER } from '../Types';

//kompletni data daneho uzivatele
export const fetchUser = data => {
    return{
       type: FETCH_USER,
       payload: {
          api_token: data.api_token,
          user: data.user,
          logged_in: true
       }
    }
 };