import { DASHBOARD_COUNTS } from '../Types';
import update from 'immutability-helper';

const def_state = {
   counts: {},
};

export default function user(state = def_state, action){
   switch(action.type){
      case DASHBOARD_COUNTS:
         return update(state, {counts: {$set: action.payload}});
      default:
         return state;
   }
};