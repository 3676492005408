import { 
   ORDERS_TIMES, 
   DURATIONS_TIMES, 
   ORDERS_TIMES_SELECTED, 
   SETTINGS_CALENDAR_FLUSH, 
   ORDERS_DATES_SELECTED_ADD, 
   ORDERS_DATES_SELECTED_DELETE, 
   ORDERS_DATES_SELECTED_FLUSH,
   ORDERS_DURATION_TIME,
   CALENDAR_DISALLOW,
   CALENDAR_ORDER_FROM,
   CALENDAR_ORDER_TO,
} from '../Types';
import update from 'immutability-helper';
import moment from 'moment';

const def_state = {
   calendar: {
      //seznam casu, pro ktere muzeme vytvorit objednavky (stahujeme z API)
      orders_times: [],
      //seznam durations pro objednavky (stahujeme z API)
      durations_times: [],
      //id vybrane delky trvani jednoho ukonu
      order_duration: null,
      //pole jednotlivych datumu pro ktere vytvarime objednavky
      orders_dates_selected: [],
      //pole jednotlivych casu objednavek vybranych uzivatelem
      orders_times_selected: [],
      //seznam datumu, na kterych je již objednavka vytvorena - nemuze vytvaret dalsi (bohuzel)
      disallow_dates: [],
      order_from: moment("1/1/1999 08:00:00").locale("cs"),
      order_to: moment("1/1/1999 17:00:00").locale("cs"),
   },
};

export default function settings(state = def_state, action){
   switch(action.type){
      case ORDERS_TIMES:
         return update(state, {calendar: {orders_times: {$set: action.payload}}});
      case DURATIONS_TIMES:
         return update(state, {calendar: {durations_times: {$set: action.payload}}});
      case ORDERS_TIMES_SELECTED:
         return update(state, {calendar: {orders_times_selected: {$set: action.payload}}});
      case SETTINGS_CALENDAR_FLUSH:
         return update(state, {calendar: {orders_dates_selected: {$set: []}, orders_times_selected: {$set: []}, duration_times: {$set: []}}});
      case ORDERS_DATES_SELECTED_ADD:
         return update(state, {calendar: {orders_dates_selected: {$push: [action.payload]}}});
      case ORDERS_DATES_SELECTED_DELETE:
         return update(state, {calendar: {orders_dates_selected: {$splice: [[action.payload, 1]]}}});
      case ORDERS_DATES_SELECTED_FLUSH:
         return update(state, {calendar: {orders_dates_selected: {$set: []}}});
      case ORDERS_DURATION_TIME:
         return update(state, {calendar: {order_duration: {$set: action.payload}}});
      case CALENDAR_DISALLOW:
         return update(state, {calendar: {disallow_dates: {$set: action.payload}}});
      case CALENDAR_ORDER_FROM:
         return update(state, {calendar: {order_from: {$set: action.payload}}});
      case CALENDAR_ORDER_TO:
         return update(state, {calendar: {order_to: {$set: action.payload}}});
      default:
         return state;
   }
};